<template>
	<div class="cont">
		<test></test>
		<img src="./images/banner2@1x.png" style="width: 100%;" >
		<div class="product">
		<h3>常见问题</h3>
			<img src="./images/zp_18.png" />
			<ul>
				<li>
					<img src="./images/xficon_16.png" />
					<div class="r_txt">
						<h3>是否合法合规？</h3>
						<p>公司是由中国人民银行、中国银行保险监督管理委员会、青岛市地方金融监督管理局批复的，全省首家全国性（线上） 网络小贷公司，
						总部设在青岛。我们严格遵守国家政策，保证息费透明。免费申请，无年费，无管理费。</p>
					</div>
				</li>
				<li>
					<img src="./images/xf_16.png" />
					<div class="r_txt">
						<h3>产品额度是怎么给出的？</h3>
						<p>额度是平台根据客户的综合评估情况由平台自动计算给出的，最终额度由审批结果为准。</p>
					</div>
				</li>
				<li>
					<img src="./images/xf_20.png" />
					<div class="r_txt">
						<h3>产品的额度如何提升？</h3>
						<p>公司会自动根据用户综合情况进行分析，并对符合条件的用户展示提升额度入口，若有“提升额度”按钮可尝试申请。
						平台会不定期更新客户的个人评价，按时还款有助于提高额度。</p>
					</div>
				</li>
				<li>
					<img src="./images/xf_22.png" />
					<div class="r_txt">
						<h3>是否会影响征信？</h3>
						<p>根据国家法律法规要求，公司必须将客户的使用情况报送中国人民银行征信系统，这只是如实反馈客户的历史使用记录。
						这有助于金融机构评估客户的个人信用状况，按时还款会有助于客户的信用积累。</p>
					</div>
				</li>
				<li>
					<img src="./images/xf_25.png" />
					<div class="r_txt">
						<h3>咨询更多事项</h3>
						<p>可拨打客服电话400-068-7979咨询。</p>
					</div>
				</li>					
			</ul>
		</div>
		<div class="Swindle">
			<h3>关于保护消费者权益谨防诈骗的提示</h3>
			<span></span>
			<div class="sw_div">
				<ul>
					<li>
						<h2>1.</h2>
						<div class="h_tit">
							<h3>谨防诈骗 防范未然</h3>
							<p>常见的金融诈骗套路有：冒用本公司名义，通过网站、微信、微博、论坛、短信和电话等方式，
							传递虚假信息，虚假申请或谎称我司员工，诱导客户打款、下载假冒APP等实施诈骗。</p>
						</div>
					</li>
					<li>
						<h2>2.</h2>
						<div class="h_tit">
							<h3>远离骗子 四个不要</h3>
							<p>1、不要在贷款到账前，缴纳任何名义的任何前期费用;<br>
							2、不要在除官网、官微、正规应用市场外的任何渠道下载客户端;<br>
							3、不要在官网、官微以外的渠道提交个人信息或缴纳费用；<br>
							4、不要转账给任何个人账户。</p>
						</div>
					</li>
					<li>
						<h2>3.</h2>
						<div class="h_tit">
							<h3>认准国晟普惠官方通道</h3>
							<li>
								<img src="./images/zp_23.png"  style="width: 100px;" />
								<div class="left">
									<h4>微信公众号：国晟普惠</h4>
									<p>扫码关注公众号，了解更多内容。</p>
								</div>
								<div class="kf">
									<h3>官方客服：400-068-7979</h3>
									<!-- <p>在线客服请通过官方微信公众号和官网进入</p> -->
								</div>
							</li>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	components: {
		test,
		foot	
	},
	data(){
		return{
			list:[]
		}
	},
	created(){
		
	}
}
</script>

<style>
	.cont p{
		font-size: 12px;
		color: #666;
		margin-top: 10px;
	}
	.Swindle .sw_div ul li .h_tit .kf{
		margin-right: 20px;
		margin-top: 20px;
	}
	.Swindle .sw_div ul li .h_tit .left{
		padding-top: 20px;
		margin-left: 30px;
		flex: 6;
	}
	.Swindle .sw_div ul li .h_tit li img{
		flex: 0.2;
	}
	.Swindle .sw_div ul li .h_tit>h3{
		margin-bottom: 30px;
	}
	
	.Swindle .sw_div ul li .h_tit p{
		color: #fff;
		line-height: 2;
	}
	.Swindle .sw_div ul li h2{
		flex: 0.5;
		font-size: 30px;
		line-height: 50px;
	}
	.Swindle .sw_div ul li .h_tit{
		flex: 5;
		text-align: left;
		color: #fff;
	}
	.Swindle .sw_div ul li{
		margin-bottom: 30px;
		display: flex;
		justify-content: center;
		
	}
	.Swindle .sw_div{
		width: 65%;
		/* border: 1px solid #fff; */
		margin: 5% auto 0 auto;
		padding: 2% 0;
	}
	.Swindle>span{
		display: block;
		width: 40px;
		margin: 15px auto;
		height: 2px;
		background-color: #ea0029;
	}
	.Swindle h3{
		color: #fff;
	}
	.Swindle{
		background: url(./images/bg2@1x.png) no-repeat;
		background-size: 100% 100%;
		width: 100%;
		color: #fff;
		padding-top: 5%;
	}
	.product ul{
		overflow: hidden;
		width: 65%;
		margin: 20px auto;
	}
	.product ul li{
		width: 48%;
		height: 140px;
		margin: 1%;
		float: left;
		display: flex;
		justify-content: center;
	}
	.product ul li img{
		flex: 0.4;
		
	}
	.product ul li .r_txt h3{
		font-size: 16px;
	}
	.product ul li .r_txt{
		flex: 3.5;
		text-align: left;
		margin-left: 20px;
		padding-top: 20px;
		
	}
	.team ul{
		margin-top: 5%;
	}
	.team ul li .t_txt h4{
		font-size: 22px;
		margin-bottom: 20px;
	}
	.team ul li .t_txt p{
		font-size: 14px;
		margin-top: 20px;
	}
	.team ul li .t_txt h6{
		
	}
	.team ul li{
		margin-bottom: 5%;
		display: flex;
		justify-content: center;
		/* align-items: center; */
	}
	.team ul li .t_txt{
		flex:6;
		text-align: left;
		margin-left: 5%;
	}
	.team ul li img{
		width: 100px;
		height: 150px;
		margin-top: 54px;
	}
	.team{
		color: #333;
		padding: 3% 0;
		width: 65%;
		margin: 20px auto;
	}
	.a_main .a_left{
		flex: 3;
	}
	.a_main .a_left li>img{
		width: 60px;
	}
	.a_main .a_left li{
		margin-bottom: 15%;
		text-align: left;
	}
	.a_main .a_left li p{
		font-size: 12px;
		margin-top: 10px;
	}
	.a_main .a_left li h3{
		font-weight: normal;
		
	}
	.a_main>img{
		width: 70%;
	}
	.a_main{
		width: 65%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}
	.a_sm{
		width: 100%;
		background-color: #f3f3f3;
		padding: 5% 0;
	}
	body{
		background-color: #fff;
	}
	.about{
		width: 800px;
		margin: 60px auto;
	}
	.about p{
		line-height: 2;
		margin-bottom: 20PX;
		font-size: 13PX;
	}
</style>
